'use client';

import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { CldImage } from '@/global/components/CldImage/CldImage';
type Props = {
  src: string;
  alt: string;
};
export const FullWidthImageWithCTAImage = ({
  src,
  alt
}: Props) => {
  const isMobile = useIsMobile();
  return <CldImage src={src} fill quality={100} priority style={{
    objectPosition: isMobile ? 'center center' : 'left center',
    objectFit: 'cover',
    zIndex: 1
  }} alt={alt} data-sentry-element="CldImage" data-sentry-component="FullWidthImageWithCTAImage" data-sentry-source-file="FullWidthImageWithCTAImage.tsx" />;
};