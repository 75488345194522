import { Link as MuiLink, Skeleton, Stack, Typography } from '@mui/material';
import { PreserveAspectRatioImage } from '@qb/frontend/components/PreserveAspectRatioImage';
import { getSPSManufacturerURL } from '@qb/sps/src/global/utils/spsURLs';
import { SearchPartAutocompleteOmniResponse } from '@/shared/types/controllers/SearchControllerTypes';
type ManufacturerResultProps = {
  manufacturer: SearchPartAutocompleteOmniResponse['parts'][0]['manufacturer'];
};
export const ManufacturerResultSkeleton = () => {
  return <Skeleton width="100%" height={30} data-sentry-element="Skeleton" data-sentry-component="ManufacturerResultSkeleton" data-sentry-source-file="ManufacturerResult.tsx" />;
};
export const ManufacturerResult = ({
  manufacturer
}: ManufacturerResultProps) => {
  return <Stack direction="row" spacing={1} component={MuiLink} href={getSPSManufacturerURL(manufacturer)} sx={{
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    alignItems: 'center'
  }} data-sentry-element="Stack" data-sentry-component="ManufacturerResult" data-sentry-source-file="ManufacturerResult.tsx">
      <PreserveAspectRatioImage src={manufacturer.logoURL} alt={manufacturer.name} width={30} height={30} data-sentry-element="PreserveAspectRatioImage" data-sentry-source-file="ManufacturerResult.tsx" />
      <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="ManufacturerResult.tsx">{manufacturer.name}</Typography>
    </Stack>;
};