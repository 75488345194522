'use client';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
type CarouselArrowProps = {
  onClick?: () => void;
  variant: 'prev' | 'next';
  isSmallSize?: boolean;
};
export const BaseCarouselArrow = ({
  onClick,
  variant,
  isSmallSize = false
}: CarouselArrowProps) => {
  const xPosition = isSmallSize ? 0 : {
    xs: -10,
    sm: -20,
    lg: -28
  };
  return <IconButton aria-label={variant === 'next' ? 'Next slide' : 'Previous slide'} onClick={onClick} color="primaryShared" sx={theme => ({
    width: isSmallSize ? 24 : {
      xs: 40,
      lg: 56
    },
    height: isSmallSize ? 24 : {
      xs: 40,
      lg: 56
    },
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    right: variant === 'next' ? xPosition : 'auto',
    left: variant === 'prev' ? xPosition : 'auto',
    p: 0,
    zIndex: theme.zIndex.mobileStepper,
    boxShadow: isSmallSize ? theme.shadows[2] : theme.shadows[6],
    backgroundColor: 'common.white',
    '&:hover, &:focus': {
      backgroundColor: 'common.white',
      textDecoration: 'underline'
    }
  })} data-sentry-element="IconButton" data-sentry-component="BaseCarouselArrow" data-sentry-source-file="BaseCarouselArrow.tsx">
      {variant === 'next' ? <ArrowForwardIcon sx={{
      fontSize: isSmallSize ? 12 : 24
    }} /> : <ArrowBackIcon sx={{
      fontSize: isSmallSize ? 12 : 24
    }} />}
    </IconButton>;
};