'use client';

import { CldImage as CldImageDefault, CldImageProps } from 'next-cloudinary';
import { getS3BucketName } from '@qb/frontend/utils/getS3BucketName';
export const BROKEN_IMAGE_PLACEHOLDER = '---';
export const CldImage = ({
  src,
  alt,
  ...remainingProps
}: CldImageProps) => {
  if (src === BROKEN_IMAGE_PLACEHOLDER) {
    return <img src={src} alt={alt} />;
  }
  if (!src) return null;
  return <CldImageDefault src={getCloudinaryURLFromS3URL(src)} alt={alt} {...remainingProps} data-sentry-element="CldImageDefault" data-sentry-component="CldImage" data-sentry-source-file="CldImage.tsx" />;
};
export const getCloudinaryURLFromS3URL = (s3URL: string): string => {
  let cloudinaryPublicID = '';
  if (s3URL) {
    const mainImageURL = new URL(s3URL);
    cloudinaryPublicID = `${getS3BucketName()}${mainImageURL.pathname}`;
  }
  return cloudinaryPublicID;
};