import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Stack, Typography } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { clampLinesSx } from '@qb/frontend/utils/clampLinesSx';
import { formatCents, getDiscountPercentage } from '@qb/frontend/utils/formatCurrency';
import { TenantConfig } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import { PartRelevantPartsDataResponse } from '@/shared/types/controllers/sps/SPSPartControllerTypes';
type StockFoundProps = {
  stock: number;
};
const StockFound = ({
  stock
}: StockFoundProps) => <Stack spacing={1} sx={{
  alignItems: 'flex-end'
}} data-sentry-element="Stack" data-sentry-component="StockFound" data-sentry-source-file="PartCardHorizontalInner.tsx">
    <Stack direction="row" spacing={0.5} sx={{
    alignItems: 'center'
  }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
      <CheckCircleIcon sx={{
      fontSize: '14px'
    }} color="success" data-sentry-element="CheckCircleIcon" data-sentry-source-file="PartCardHorizontalInner.tsx" />
      <Typography variant="bodyMediumPrimary" sx={{
      color: 'text.primary'
    }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
        STOCK: {stock}
      </Typography>
    </Stack>
  </Stack>;
const QuickQuote = () => <Stack direction="row" spacing={0.5} sx={{
  alignItems: 'center',
  justifyContent: 'end'
}} data-sentry-element="Stack" data-sentry-component="QuickQuote" data-sentry-source-file="PartCardHorizontalInner.tsx">
    <CheckCircleIcon sx={{
    fontSize: '14px'
  }} color="info" data-sentry-element="CheckCircleIcon" data-sentry-source-file="PartCardHorizontalInner.tsx" />
    <Typography variant="bodyMediumPrimary" align="center" sx={{
    color: 'text.primary'
  }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
      Quick Quote
    </Typography>
  </Stack>;
type PartCardHorizontalInnerProps = {
  tenantConfig: TenantConfig;
  part: PartRelevantPartsDataResponse['parts'][0];
};
export const PartCardHorizontalInner = ({
  tenantConfig,
  part
}: PartCardHorizontalInnerProps) => {
  const discountPercentage = getDiscountPercentage(part.originalPrice, part.price);
  const isDiscounted = !!discountPercentage;
  const combinedStock = part.onHandQty + part.manufacturerStock;
  return <Stack direction="row" spacing={2} sx={{
    alignItems: 'center',
    py: 1,
    px: 1.5,
    minWidth: 200,
    width: '100%'
  }} data-sentry-element="Stack" data-sentry-component="PartCardHorizontalInner" data-sentry-source-file="PartCardHorizontalInner.tsx">
      <ImageWithFallback tenantAlias={tenantConfig.tenantAlias} src={part.images[0]?.target} width={50} height={50} alt={`${part.partNumber} on ${tenantConfig.companyName}`} sx={{
      flexShrink: 0
    }} data-sentry-element="ImageWithFallback" data-sentry-source-file="PartCardHorizontalInner.tsx" />
      <Stack direction="column" sx={{
      width: '100%',
      overflow: 'hidden'
    }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
        <Stack direction="row" spacing={1} sx={{
        justifyContent: 'space-between',
        mb: 1
      }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="h6" sx={{
          mr: 'auto'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.partNumber}
          </Typography>
          <Stack direction="column" sx={{
          gap: 0.5
        }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {!!part.price && !isDiscounted && <Typography variant="h6" color="primary">
                {formatCents(part.price)}
              </Typography>}
            {!!part.price && isDiscounted && <Typography variant="h6" color="primary" sx={{
            px: 1,
            bgcolor: 'accent.main',
            borderRadius: '50px'
          }}>
                {formatCents(part.price)}
              </Typography>}
            {!!part.originalPrice && isDiscounted && <Typography variant="bodySmall" align="center" sx={{
            fontWeight: 600,
            textDecoration: 'line-through'
          }}>
                {formatCents(part.originalPrice)}
              </Typography>}
          </Stack>
        </Stack>
        <Typography title={part.description} variant="bodyMedium" sx={[{
        mb: 1
      }, clampLinesSx(1)]} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
          {part.description}
        </Typography>
        <Stack direction="row" sx={{
        mt: 'auto',
        justifyContent: 'space-between'
      }} data-sentry-element="Stack" data-sentry-source-file="PartCardHorizontalInner.tsx">
          <Typography variant="bodyMediumPrimary" noWrap sx={{
          color: 'primary.light'
        }} data-sentry-element="Typography" data-sentry-source-file="PartCardHorizontalInner.tsx">
            {part.manufacturer.name}
          </Typography>
          {combinedStock ? <StockFound stock={combinedStock} /> : <QuickQuote />}
        </Stack>
      </Stack>
    </Stack>;
};