'use client';

import { ListItemButton } from '@mui/material';
import { PartCardHorizontalInner } from '@qb/frontend/components/PartCards/components/PartCardHorizontalInner';
import { TenantConfig } from '@/shared/types/controllers/sps/SPSAppControllerTypes';
import { SearchPartAutocompleteResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { generatePartURL } from '@/shared/utils/URLs';
type PartListItemButtonProps = {
  tenantConfig: TenantConfig;
  part: SearchPartAutocompleteResponse['parts'][0];
  onClick: () => void;
};
export const PartListItemButton = ({
  tenantConfig,
  part,
  onClick
}: PartListItemButtonProps) => {
  return <ListItemButton key={part.id} href={generatePartURL(part.manufacturer.name, part.partNumber, part.id)} onClick={onClick} sx={theme => ({
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  })} data-sentry-element="ListItemButton" data-sentry-component="PartListItemButton" data-sentry-source-file="PartListItemButton.tsx">
      <PartCardHorizontalInner part={part} tenantConfig={tenantConfig} data-sentry-element="PartCardHorizontalInner" data-sentry-source-file="PartListItemButton.tsx" />
    </ListItemButton>;
};