'use client';

import pick from 'lodash/pick';
import { Box, useTheme } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
function adjustFontSizes(html: string): string {
  const minimumFontSize = 24;
  return html.replace(/(font-size:\s*)(\d+(?:\.\d+)?)px/gi, (_match, prefix, fontSize) => `${prefix}${parseFloat(fontSize) > minimumFontSize ? (parseFloat(fontSize) * 0.75).toFixed(0) : fontSize}px`);
}
type PreStyledRichTextFieldProps = {
  htmlContent: string;
  isResponsive?: boolean;
};
export const PreStyledRichTextField = ({
  htmlContent: initialHtmlContent,
  isResponsive = false
}: PreStyledRichTextFieldProps) => {
  const theme = useTheme();
  const isMobile = useIsUnderBreakpoint('md');
  const tags: (keyof typeof theme.typography)[] = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'paragraph', 'caption'];
  const typographyStyles = pick(theme.typography, tags);
  const responsiveStyles = {
    h1: {
      fontSize: 36
    },
    h2: {
      fontSize: 26
    },
    h3: {
      fontSize: 20
    },
    h4: {
      fontSize: 16
    }
  };
  const htmlContent = isResponsive && isMobile ? adjustFontSizes(initialHtmlContent) : initialHtmlContent;
  return <Box sx={{
    /**
     * Keep synced with useTinyMceConfig.tsx
     */
    ...typographyStyles,
    'h1,h2,h3': {
      m: 0,
      mb: 3
    },
    'h4,h5,h6,p': {
      m: 0,
      mb: 2
    },
    '& p': typographyStyles.paragraph,
    [theme.breakpoints.down('md')]: isResponsive ? responsiveStyles : {}
  }} dangerouslySetInnerHTML={{
    __html: htmlContent
  }} data-sentry-element="Box" data-sentry-component="PreStyledRichTextField" data-sentry-source-file="PreStyledRichTextField.tsx" />;
};