'use client';

import { Skeleton, Stack, SxProps, Typography, Link as MuiLink } from '@mui/material';
import { ImageWithFallback } from '@qb/frontend/components/ImageWithFallback';
import { removeWhiteBackgroundSx } from '@qb/frontend/utils/removeWhiteBackgroundSx';
import { getSPSCategoryURL } from '@qb/sps/src/global/utils/spsURLs';
import { PartCategorySearchCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { SearchPartCategoryAutocompleteOmniResponse } from '@/shared/types/controllers/SearchControllerTypes';
type PartCategoryResultProps = {
  tenantAlias: string;
  partCategory: SearchPartCategoryAutocompleteOmniResponse['partCategories'][0] | PartCategorySearchCategoriesResponse['searchCategories'][0];
  sx?: SxProps;
  onClick?: () => void;
};
export const PartCategoryResult = ({
  tenantAlias,
  partCategory,
  onClick,
  sx
}: PartCategoryResultProps) => {
  return <Stack direction="row" spacing={1} component={MuiLink} onClick={onClick} href={getSPSCategoryURL(partCategory)} sx={{
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    alignItems: 'center',
    ...sx
  }} data-sentry-element="Stack" data-sentry-component="PartCategoryResult" data-sentry-source-file="PartCategoryResult.tsx">
      <ImageWithFallback tenantAlias={tenantAlias} unoptimized src={partCategory.imageURL} alt={partCategory.name} width={24} height={24} sx={{
      flexShrink: 0,
      ...removeWhiteBackgroundSx
    }} data-sentry-element="ImageWithFallback" data-sentry-source-file="PartCategoryResult.tsx" />
      <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="PartCategoryResult.tsx">{partCategory.name}</Typography>
    </Stack>;
};
export const PartCategoryResultSkeleton = () => {
  return <Skeleton width="100%" height={30} data-sentry-element="Skeleton" data-sentry-component="PartCategoryResultSkeleton" data-sentry-source-file="PartCategoryResult.tsx" />;
};