'use client';

import { Stack } from '@mui/material';
import { FacetChips } from '@/global/pages/Search/_components/FacetChips';
import { ManufacturerFacetChip } from '@/global/pages/Search/_components/ManufacturerFacetChip';
import { SearchDataResponse } from '@/shared/types/controllers/sps/SPSSearchControllerTypes';
import { isNonEmptyArray } from '@/shared/types/util/TypeGuards';
type ActiveFacetsProps = {
  facets?: SearchDataResponse['facets'];
  manufacturerFacets?: SearchDataResponse['manufacturerFacets'];
};
export const ActiveFacets = ({
  facets,
  manufacturerFacets
}: ActiveFacetsProps) => {
  return <Stack direction="row" sx={{
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    flexGrow: 1,
    gap: 1
  }} data-sentry-element="Stack" data-sentry-component="ActiveFacets" data-sentry-source-file="ActiveFacets.tsx">
      {isNonEmptyArray(manufacturerFacets) && manufacturerFacets.map(manufacturerFacet => <ManufacturerFacetChip key={manufacturerFacet.id} manufacturerFacet={manufacturerFacet} />)}
      {isNonEmptyArray(facets) && facets.map(facet => <FacetChips key={facet.camelCaseName} facet={facet} />)}
    </Stack>;
};